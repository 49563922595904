var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "astroCalendadrWrap" }, [
    _c(
      "div",
      { staticClass: "astroCalendar" },
      [
        _vm._m(0),
        _c("div", { staticClass: "astroCalendar__nav" }, [
          _c(
            "div",
            {
              staticClass: "astroCalendar__nav__prev",
              on: { click: _vm.preMonth }
            },
            [_vm._v("上一月")]
          ),
          _c("div", { staticClass: "astroCalendar__nav__date" }, [
            _vm._v(_vm._s(_vm.calendarMonthTitle))
          ]),
          _c(
            "div",
            {
              staticClass: "astroCalendar__nav__next",
              on: { click: _vm.nextMonth }
            },
            [_vm._v("下一月")]
          )
        ]),
        _c("div", {
          staticClass: "astroCalendar__main",
          attrs: { id: "calendar" }
        }),
        _c("Tab", {
          attrs: {
            tabList: _vm.filterOptionList,
            currentValue: _vm.currentFitler
          },
          on: {
            change: function($event) {
              return (_vm.currentFitler = $event)
            }
          }
        }),
        _c(
          "div",
          { staticClass: "astroCalendar__listBox" },
          [
            _vm._m(1),
            _vm._l(_vm.currentMonthEventsFiltered, function(event) {
              return _c(
                "div",
                { key: event.id, staticClass: "astroCalendar__listBox__row" },
                [
                  _c("div", { staticClass: "astroCalendar__listBox__cell" }, [
                    _vm._v(_vm._s(event.title))
                  ]),
                  _c("div", { staticClass: "astroCalendar__listBox__cell" }, [
                    _vm._v(_vm._s(event.start))
                  ])
                ]
              )
            })
          ],
          2
        ),
        _vm.calendarAstrolabeObj
          ? _c("CalendarAstrolabePopup", {
              attrs: { calendarAstrolabeObj: _vm.calendarAstrolabeObj },
              on: {
                close: function($event) {
                  _vm.calendarAstrolabeObj = null
                }
              }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "astroCalendar__title" }, [
      _c("div", [_vm._v("2025 星歷")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "astroCalendar__listBox__row header" }, [
      _c("div", { staticClass: "astroCalendar__listBox__cell" }, [
        _vm._v("發生事件")
      ]),
      _c("div", { staticClass: "astroCalendar__listBox__cell" }, [
        _vm._v("時間")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }