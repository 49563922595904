var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "book" }, [
    _c("div", { staticClass: "book__banner" }, [
      _c("div", { staticClass: "book__banner__textPic" }),
      _c("div", { staticClass: "book__banner__toBuy" }, [
        _c(
          "div",
          {
            staticClass: "book__banner__toBuy__btn all",
            class: { isSale: _vm.isSale },
            on: {
              click: function($event) {
                return _vm.toBuyAll()
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "book__banner__toBuy__btn__text",
                class: { "hover--hide": _vm.isSale }
              },
              [
                _vm._v("\n          全部"),
                _c("br", { staticClass: "hidden-mobile-down" }),
                _vm._v("購買\n        ")
              ]
            ),
            _vm.isSale
              ? _c(
                  "div",
                  { staticClass: "book__banner__toBuy__btn__text hover--show" },
                  [
                    _c("span", [_vm._v("解鎖")]),
                    _c("br", { staticClass: "hidden-mobile-down" }),
                    _vm._v("2024大趨勢\n        ")
                  ]
                )
              : _vm._e(),
            _c("div", { staticClass: "book__banner__toBuy__btn__price" }, [
              _vm._v("\n          NT400\n          "),
              _c("img", {
                class: { "hover--hide": _vm.isSale },
                attrs: {
                  src: require("@/assets/運勢書共用/toBuyIcon.svg"),
                  alt: "解答之書全部購買"
                }
              }),
              _vm.isSale
                ? _c("img", {
                    staticClass: "hover--show",
                    attrs: {
                      src: require("@/assets/運勢書共用/toBuyIcon_預購白.svg"),
                      alt: "解答之書全部購買_預購"
                    }
                  })
                : _vm._e()
            ])
          ]
        ),
        _c(
          "div",
          {
            staticClass: "book__banner__toBuy__btn single",
            class: { isSale: _vm.isSale },
            on: {
              click: function($event) {
                return _vm.toUnlockList()
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "book__banner__toBuy__btn__text",
                class: { "hover--hide": _vm.isSale }
              },
              [
                _vm._v("\n          單個"),
                _c("br", { staticClass: "hidden-mobile-down" }),
                _vm._v("星座\n        ")
              ]
            ),
            _vm.isSale
              ? _c(
                  "div",
                  { staticClass: "book__banner__toBuy__btn__text hover--show" },
                  [
                    _c("span", [_vm._v("解鎖")]),
                    _c("br", { staticClass: "hidden-mobile-down" }),
                    _vm._v("2024大趨勢\n        ")
                  ]
                )
              : _vm._e(),
            _c("div", { staticClass: "book__banner__toBuy__btn__price" }, [
              _vm._v("\n          NT100\n          "),
              _c("img", {
                class: { "hover--hide": _vm.isSale },
                attrs: {
                  src: require("@/assets/運勢書共用/toBuyIcon.svg"),
                  alt: "解答之書-單星座購買"
                }
              }),
              _vm.isSale
                ? _c("img", {
                    staticClass: "hover--show",
                    attrs: {
                      src: require("@/assets/運勢書共用/toBuyIcon_預購白.svg"),
                      alt: "解答之書-單星座購買預購"
                    }
                  })
                : _vm._e()
            ])
          ]
        )
      ]),
      _vm.isSale
        ? _c(
            "div",
            { staticClass: "book__banner__mobilePreorder hidden-mobile-up" },
            _vm._l(_vm.mobilePreorderText, function(char, index) {
              return _c(
                "span",
                {
                  key: index,
                  staticClass: "wave-char",
                  style: {
                    animationDelay:
                      index * (1.0 / _vm.mobilePreorderText.length) + "s"
                  }
                },
                [_vm._v("\n        " + _vm._s(char) + "\n      ")]
              )
            }),
            0
          )
        : _vm._e()
    ]),
    _c("div", { staticClass: "book__main" }, [
      _c("h1", { staticClass: "hidden-mobile-up hidden-mobile-down" }, [
        _vm._v("2024 解答之書")
      ]),
      _vm._m(0),
      _c("div", { staticClass: "book__main__constellations" }, [
        _vm._m(1),
        _c(
          "div",
          { staticClass: "book__main__constellations__list" },
          _vm._l(_vm.book2024_list, function(item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "book__main__constellations__list__item",
                on: {
                  click: function($event) {
                    return _vm.toBuyOrRead(item)
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "book__main__constellations__list__item__pic",
                    class: "background-" + ((index % 3) + 1)
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: item.bookImg,
                        alt: "解答之書-" + item.cn + "座圖片"
                      }
                    })
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "book__main__constellations__list__item__title"
                  },
                  [
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !item.isUnlock,
                          expression: "!item.isUnlock"
                        }
                      ],
                      attrs: {
                        src: require("../../assets/運勢書共用/icon-locked.svg"),
                        alt: "解答之書-需要解鎖"
                      }
                    }),
                    _vm._v(
                      "\n            " + _vm._s(item.cn) + "座\n          "
                    )
                  ]
                )
              ]
            )
          }),
          0
        )
      ])
    ]),
    _vm.needAnimation
      ? _c(
          "div",
          {
            staticClass: "book__animation",
            class: { "fade-out": !_vm.showAnimation }
          },
          [
            _c("div", { staticClass: "book__animation__video" }, [
              _c(
                "video",
                {
                  ref: "video",
                  attrs: {
                    muted: "",
                    playsinline: "true",
                    "webkit-playsinline": "true"
                  },
                  domProps: { muted: true },
                  on: { loadedmetadata: _vm.handleVideoLoaded }
                },
                [
                  _c("source", {
                    attrs: {
                      src: require("../../assets/運勢書共用/taiwanNo1.mp4"),
                      type: "video/mp4"
                    }
                  }),
                  _vm._v(
                    "\n        很抱歉，您的瀏覽器似乎不支援 HTML5 的 video\n        標籤。為了獲得最佳的觀看體驗，請考慮切換到一個支援 HTML5 video\n        的瀏覽器。\n      "
                  )
                ]
              )
            ]),
            _c(
              "div",
              {
                staticClass: "book__animation__loading",
                class: { "fade-out": !_vm.isLoading && _vm.videoLoaded }
              },
              [
                _c("img", {
                  staticClass: "hidden-mobile-down",
                  attrs: {
                    src: require("../../assets/運勢書共用/Desktop-Loading.png"),
                    alt: "loading-desktop"
                  },
                  on: {
                    load: function($event) {
                      return _vm.initAnimation()
                    }
                  }
                }),
                _c("img", {
                  staticClass: "hidden-mobile-up",
                  attrs: {
                    src: require("../../assets/運勢書共用/Mobile-Loading.png"),
                    alt: "loading-mobile"
                  }
                })
              ]
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "book__main__quote" }, [
      _c("div", { staticClass: "book__main__quote__title" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/運勢書共用/2024.svg"),
            alt: "解答之書-裝飾圖片1"
          }
        }),
        _c("br"),
        _vm._v("會是怎麼樣的一年\n      ")
      ]),
      _c("div", { staticClass: "book__main__quote__content" }, [
        _vm._v(
          "\n        2024年，冥王用「最後八個月」幫我們暖身，給出膽量讓我們嘗試。"
        ),
        _c("span", [_vm._v("動作夠快、懂抓住機會的人")]),
        _vm._v("，就有高機率能異軍崛起，甚至撼動未來二十年生涯。"),
        _c("br"),
        _c("br"),
        _vm._v(
          "2024年四月前，也是木天合高峰，我們即將充分感受天王星能量。階級顛覆、規則打破、新生代出頭，各種創意蓬發"
        ),
        _c("br"),
        _c("br"),
        _vm._v("面對新世界，我們不能再用舊的自己迎接"),
        _c("br"),
        _c("span", [_vm._v("需打破習性")]),
        _vm._v("，新的價值觀，新的表達方式面對"),
        _c("br"),
        _c("br"),
        _vm._v("你已躍躍欲試了嗎？記得喔，改變的意義不在「馬上成功」，"),
        _c("span", [_vm._v("改變是為了給自己一個新的機會")]),
        _vm._v("，現在起，開始改變，你已拿到進入新世界的門票，加油喔！\n      ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "book__main__constellations__title" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/運勢書共用/探索星座.svg"),
          alt: "解答之書-探索星座"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }