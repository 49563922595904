<template>
  <div class="UITab">
    <swiper id="sw" :options="swiperInterpret" style="padding: 5px 5px">
      <swiper-slide
        v-for="(tab, index) in tabList"
        :key="index"
        class="UITab__item"
        @click.native="changeEvent(tab)"
        :class="{
          active: currentValue === tab.value,
        }"
      >
        <span class="iconfont UITab__item__icon" v-if="tab.webIcon">{{
          tab.webIcon
        }}</span>
        <span class="UITab__item__text">{{ tab.label }}</span>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
export default {
  props: {
    tabList: {
      type: Array,
      default: () => [],
    },
    currentValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      swiperInterpret: {
        slidesPerView: "auto",
        spaceBetween: 12,
        freeMode: true,
      },
    };
  },
  methods: {
    changeEvent(tab) {
      this.$emit("change", tab.value, tab);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/_mixin.scss";

.UITab {
  position: relative;
  margin-top: 24px;
  @include align(flex-start, center);
  .UITab__item {
    width: 48px;
    height: 72px;
    background-color: rgba(#0c2f58, 50%);
    border-radius: 24px;
    @include center;
    flex-direction: column;
    border: 1px solid #a494d5;
    cursor: pointer;
    &.active {
      background-color: rgba(#d3409d, 0.5);

      .UITab__item__icon {
        color: #ffffff;
      }

      .UITab__item__text {
        color: #ffffff;
      }
    }
    .UITab__item__icon {
      font-size: 18px;
    }

    .UITab__item__text {
      writing-mode: vertical-rl;
      color: #fff;
      font-size: 14px;
      line-height: 1.57;
      letter-spacing: 0.56px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>