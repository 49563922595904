var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      {
        staticClass: "calendarAstrolabePopup",
        on: {
          click: function($event) {
            return _vm.close()
          }
        }
      },
      [
        _c(
          "div",
          {
            staticClass: "calendarAstrolabe",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return function() {}.apply(null, arguments)
              }
            }
          },
          [
            _c("div", { staticClass: "calendarAstrolabe__title" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.calendarAstrolabeObj.title) +
                  "\n      "
              )
            ]),
            _c("div", { staticClass: "calendarAstrolabe__info" }, [
              _c("div", { staticClass: "calendarAstrolabe__info__item" }, [
                _c(
                  "div",
                  { staticClass: "calendarAstrolabe__info__item__title" },
                  [_vm._v("發生時間：")]
                ),
                _c(
                  "div",
                  { staticClass: "calendarAstrolabe__info__item__content" },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.format_time) +
                        "\n          "
                    )
                  ]
                )
              ]),
              _c("div", { staticClass: "calendarAstrolabe__info__sp" }),
              _c("div", { staticClass: "calendarAstrolabe__info__item" }, [
                _c(
                  "div",
                  { staticClass: "calendarAstrolabe__info__item__title" },
                  [_vm._v("觀測地點：")]
                ),
                _c(
                  "div",
                  { staticClass: "calendarAstrolabe__info__item__content" },
                  [_vm._v("\n            台灣台北\n          ")]
                )
              ])
            ]),
            _c(
              "div",
              { staticClass: "calendarAstrolabe__astrolabe" },
              [
                _vm.TcAstralInfo
                  ? _c("canvasAstrolabe", {
                      staticClass: "canvas-pan",
                      attrs: {
                        width: _vm.paramPan.width - 2,
                        configItem: _vm.panConfigItem,
                        panStyle: "JOE2",
                        type: 0,
                        data1: _vm.TcAstralInfo.AstralRadians,
                        data2: null,
                        Ret1: _vm.TcAstralInfo.RetAstralSet,
                        role: _vm.role
                      }
                    })
                  : _c(
                      "div",
                      { staticClass: "calendarAstrolabe__astrolabe__loading" },
                      [_vm._v("\n          星盤載入中...\n        ")]
                    )
              ],
              1
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }