<template>
  <div class="astroCalendadrWrap">
    <div class="astroCalendar">
      <div class="astroCalendar__title">
        <div>2025 星歷</div>
      </div>
      <div class="astroCalendar__nav">
        <div class="astroCalendar__nav__prev" @click="preMonth">上一月</div>
        <div class="astroCalendar__nav__date">{{ calendarMonthTitle }}</div>
        <div class="astroCalendar__nav__next" @click="nextMonth">下一月</div>
      </div>
      <div class="astroCalendar__main" id="calendar"></div>

      <!-- <div class="astroCalendar_filterBar">
        <div
          class="astroCalendar_filterBar__item"
          v-for="item in filterOptionList"
          :key="item"
          @click="currentFitler = item"
        >
          {{ item }}
        </div>
      </div> -->
      <Tab
        :tabList="filterOptionList"
        :currentValue="currentFitler"
        @change="($event) => (currentFitler = $event)"
      ></Tab>

      <div class="astroCalendar__listBox">
        <div class="astroCalendar__listBox__row header">
          <div class="astroCalendar__listBox__cell">發生事件</div>
          <div class="astroCalendar__listBox__cell">時間</div>
        </div>
        <div
          class="astroCalendar__listBox__row"
          v-for="event in currentMonthEventsFiltered"
          :key="event.id"
        >
          <div class="astroCalendar__listBox__cell">{{ event.title }}</div>
          <div class="astroCalendar__listBox__cell">{{ event.start }}</div>
        </div>
      </div>
      <CalendarAstrolabePopup
        v-if="calendarAstrolabeObj"
        :calendarAstrolabeObj="calendarAstrolabeObj"
        @close="calendarAstrolabeObj = null"
      />
      <!-- <CalendarDailyPopup
        v-if="calendarDailyList.length > 0"
        :calendarDailyList="calendarDailyList"
        @close="calendarDailyList = []"
      /> -->
    </div>
  </div>
</template>

<script>
import Calendar from "@toast-ui/calendar";
import { CALENDARS_TYPE } from "@/js/calendar-type.js";
import "@toast-ui/calendar/dist/toastui-calendar.min.css";
// import CalendarDailyPopup from "@/components/運勢書2025/CalendarDailyPopup.vue";
import CalendarAstrolabePopup from "@/components/運勢書2025/CalendarAstrolabePopup.vue";
import Tab from "@/components/UI/Tab.vue";
export default {
  components: {
    // CalendarDailyPopup,
    CalendarAstrolabePopup,
    Tab,
  },
  data() {
    return {
      calendar: null,
      calendarMonthTitle: "2025年",
      calendarAstrolabeObj: null,
      calendarDailyList: [],
      events: [],
      filterOptionList: [],
      currentFitler: "進入星座",
    };
  },
  created() {
    let now = new Date();
    let start = new Date(now.getFullYear(), now.getMonth() - 1, 23);
    let end = new Date(now.getFullYear(), now.getMonth() + 1, 7);
    let startFormat = moment(start).format("YYYY/MM/DD HH:mm");
    let endFormat = moment(end).format("YYYY/MM/DD HH:mm");
    this.getCalendarData(startFormat, endFormat);
  },
  mounted() {
    let self = this;
    const container = document.getElementById("calendar");
    const options = {
      defaultView: "month",
      isReadOnly: true,
      calendars: CALENDARS_TYPE,
      month: {
        dayNames: ["日", "一", "二", "三", "四", "五", "六"],
        visibleWeeksCount: 0,
        workweek: false,
        narrowWeekend: false,
        startDayOfWeek: 0,
        isAlways6Weeks: false,
        visibleEventCount: 6,
      },
      theme: {
        common: {
          backgroundColor: "#214C73",
          border: "1px solid #ADADAD",
          color: "#FFFFFF",
          dayName: {
            borderLeft: "none",
            backgroundColor: "#214C73",
            color: "#FFFFFF",
          },
          holiday: { color: "#D3409D" },
          saturday: { color: "#FFFFFF" },
          weekend: {
            color: "#FF0000",
          },
          gridCell: {
            footerHeight: 31,
            color: "#FFFFFF",
          },
          today: {
            color: "#FFFFFF",
            backgroundColor: "#D3409D",
          },
        },
        month: {
          dayExceptThisMonth: { color: "#FFFFFF" },
          holidayExceptThisMonth: { color: "#FFFFFF" },
          moreView: {
            backgroundColor: "#214C73",
            border: "1px solid #ADADAD",
            color: "#FFFFFF",
          },
          moreViewTitle: {
            color: "#FFFFFF",
          },
          gridCell: {
            footerHeight: 31,
            color: "#FFFFFF",
          },
        },
      },
      //   useDetailPopup: true,
      template: {
        popupDetailTitle: function (data) {
          return "Detail of " + data.title;
        },
        time(event) {
          return `<span  class="hidden-mobile-down">
          ${self.parseTime(event.start.d.d)}
          </span>
          <span>
          ${event.title}</span>`;
        },
        monthGridHeaderExceed: function (hiddenEvents) {
          return `<span class="hiddenEvents" style="font-size: 11px">
            展開
            (${hiddenEvents})
            </span>`;
        },
      },
    };

    this.calendar = new Calendar(container, options);
    let day = this.calendar.getDate();
    this.calendarMonthTitle =
      day.getFullYear() + "年" + (day.getMonth() + 1) + "月";

    this.calendar.on("clickEvent", (data) => {
      this.calendarAstrolabeObj = data.event; // 傳入單一事件
      let start = data.event.start.d.d;
      // this.calendarDailyList = this.events.filter((event) => {
      //   let eventDate = new Date(event.start);
      //   let startDate = new Date(start);
      //   return (
      //     eventDate.getFullYear() === startDate.getFullYear() &&
      //     eventDate.getMonth() === startDate.getMonth() &&
      //     eventDate.getDate() === startDate.getDate()
      //   );
      // });
    });
    this.calendar.on("selectDateTime", (event) => {
      console.log(event);
    });
  },
  computed: {
    currentMonthEventsFiltered() {
      if (!this.calendar) return [];
      let currentMonth = this.calendar.getDate().getMonth();
      let currentMonthEvents = this.events
        .filter((event) => {
          let eventMonth = new Date(event.start).getMonth();
          return eventMonth === currentMonth;
        })
        .map((event) => {
          let eventStart = new Date(event.start);
          let formatter = new Intl.DateTimeFormat("zh-TW", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          });
          return {
            ...event,
            start: formatter.format(eventStart),
          };
        });

      return currentMonthEvents.filter(
        (event) => event.calendarId === this.currentFitler
      );
    },
  },
  methods: {
    preMonth() {
      this.calendar.prev();
      let day = this.calendar.getDate();
      this.calendarMonthTitle =
        day.getFullYear() + "年" + (day.getMonth() + 1) + "月";

      let start = new Date(day.getFullYear(), day.getMonth() - 1, 23);
      let end = new Date(day.getFullYear(), day.getMonth() + 1, 7);
      let startFormat = moment(start).format("YYYY/MM/DD HH:mm");
      let endFormat = moment(end).format("YYYY/MM/DD HH:mm");
      this.getCalendarData(startFormat, endFormat);
    },
    nextMonth() {
      this.calendar.next();
      let day = this.calendar.getDate();
      this.calendarMonthTitle =
        day.getFullYear() + "年" + (day.getMonth() + 1) + "月";

      let start = new Date(day.getFullYear(), day.getMonth() - 1, 23);
      let end = new Date(day.getFullYear(), day.getMonth() + 1, 7);
      let startFormat = moment(start).format("YYYY/MM/DD HH:mm");
      let endFormat = moment(end).format("YYYY/MM/DD HH:mm");
      this.getCalendarData(startFormat, endFormat);
    },
    displayEvents() {
      this.calendar.clear();
      this.calendar.createEvents(this.events);
    },
    parseTime(source) {
      let date = new Date(source);
      let hours = date.getHours();
      let minutes = date.getMinutes();
      return `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
    },
    async getCalendarData(StartDate, EndDate) {
      try {
        let res = await this.$API.GET_BOOK2025_CALENDAR(StartDate, EndDate);

        let typeMap = {
          celescal_enter: "進入星座", //進入星座
          celescal_aspect: "發生相位", //發生相位
          celescal_voc: "月空亡", // 月空亡
          celescal_full_moon: "滿月", //滿月
          celescal_new_moon: "新月", //新月
          celescal_retro: "逆行", //逆行
          celescal_direct: "順行", //順行
          celescal_lunar_eclipse: "月蝕", //月蝕
          celescal_solar_eclipse: "日蝕", //日蝕
        };
        let set = new Set();
        this.events = res.data.Data.map((item) => {
          set.add(typeMap[item.Type]);
          return {
            id: item.ID,
            calendarId: typeMap[item.Type],
            title: item.Title,
            isReadOnly: item.isReadOnly,
            isAllday: false,
            category: "time",
            start: item.StartedAt,
            end: item.EndedAt,
          };
        });
        this.displayEvents();

        this.filterOptionList = Array.from(set).map((item) => {
          return {
            label: item,
            value: item,
          };
        });
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss" >
@import "@/scss/_mixin.scss";

// astroCalendadrWrap
.astroCalendadrWrap {
  margin-top: -98px;
  padding-top: 98px;
  margin-bottom: -80px;
  padding-bottom: 80px;
  background: url("../../assets/運勢書共用/購買星座_web.png");
  min-height: 117.44vw;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: top center !important;
  @include mobile {
    margin-top: -60px;
    padding-top: 60px;
    margin-bottom: -55px;
    padding-bottom: 55px;
    min-height: 271.2vw;
    background: url("../../assets/運勢書共用/購買星座_mobile.png");
  }
}

// main
.astroCalendar {
  max-width: 1112px;
  margin: 120px auto;
  padding: 0 36px;
  @include mobile {
    padding: 0 20px;
    margin: 0 auto;
  }
  &__title {
    text-align: center;
    color: #fff;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 38.119px; /* 119.123% */
    letter-spacing: 2.56px;
    margin-bottom: 45px;
    img {
      display: block;
      width: 151px;
      margin: 00 auto;
    }
  }
  &__main {
    height: 800px;
    margin-bottom: 120px;
    box-shadow: -2px -2px 8px #fff;

    @include mobile {
      height: 800px;
    }
  }
  &__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 87px;
    @include mobile {
      padding: 0 12px;
    }
    &__prev,
    &__next {
      color: #fff;
      font-size: 18px;
      cursor: pointer;
      @include mobile {
        font-size: 14px;
      }
    }
    &__date {
      color: #fff;
      font-size: 22px;
      font-weight: 700;
      @include mobile {
        font-size: 18px;
      }
    }
  }
}

// 套件CSS覆蓋
.toastui-calendar-daygrid-cell {
  // position: relative;

  .hiddenEvents {
    color: #ffffff;
  }
}

.toastui-calendar-grid-cell-more-events {
  //hiddenEvents
  @include mobile {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 5;
  }
}

.toastui-calendar-day-names.toastui-calendar-month {
  @include mobile {
    padding: 0 !important;
  }
}

.toastui-calendar-see-more-container {
  z-index: 20 !important;
}
.toastui-calendar-weekday-event-title {
  font-size: 11.5px;
  font-weight: 400 !important;
}
.toastui-calendar-weekday-event-dot + .toastui-calendar-weekday-event-title {
  color: #fff !important;
}
.toastui-calendar-see-more-header * {
  color: #fff !important;
}
// .toastui-calendar-weekday-event-block {
//   @include mobile {
//     display: inline-block;
//   }
// }
.toastui-calendar-day-name-item.toastui-calendar-month {
  text-align: center;
}

//最下方的事件表格

.astroCalendar__listBox {
  width: 100%;
  max-width: 720px;
  margin: 36px auto;
  background-color: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  font-family: "Georgia", serif;
  color: #333;
  border-radius: 24px;
  overflow: hidden;
  border: 1px solid #adadad;
}

.astroCalendar__listBox__row {
  display: flex;
  border-bottom: 1px solid #adadad;
  text-align: center;
}

.astroCalendar__listBox__cell {
  display: flex;
  align-items: center;
  justify-content: center;
  &:nth-of-type(1) {
    width: 40%;
  }
  &:nth-of-type(2) {
    width: 60%;
  }
}

.astroCalendar__listBox__row.header .astroCalendar__listBox__cell {
  height: 76px;
  background-color: #214c73;
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.5;
  &:nth-of-type(1) {
    border-right: 1px solid #adadad;
  }
}

.astroCalendar__listBox__row:not(.header) .astroCalendar__listBox__cell {
  height: 65px;
  background-color: #214c73;
  color: #fff;
  font-size: 18px;
  line-height: 2;
  &:nth-of-type(1) {
    border-right: 1px solid #adadad;
  }
}
</style>
