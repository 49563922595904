var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "UITab" },
    [
      _c(
        "swiper",
        {
          staticStyle: { padding: "5px 5px" },
          attrs: { id: "sw", options: _vm.swiperInterpret }
        },
        _vm._l(_vm.tabList, function(tab, index) {
          return _c(
            "swiper-slide",
            {
              key: index,
              staticClass: "UITab__item",
              class: {
                active: _vm.currentValue === tab.value
              },
              nativeOn: {
                click: function($event) {
                  return _vm.changeEvent(tab)
                }
              }
            },
            [
              tab.webIcon
                ? _c("span", { staticClass: "iconfont UITab__item__icon" }, [
                    _vm._v(_vm._s(tab.webIcon))
                  ])
                : _vm._e(),
              _c("span", { staticClass: "UITab__item__text" }, [
                _vm._v(_vm._s(tab.label))
              ])
            ]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }