<template>
  <transition name="fade">
    <div class="calendarAstrolabePopup" @click="close()">
      <div class="calendarAstrolabe" @click.stop="() => {}">
        <div class="calendarAstrolabe__title">
          {{ calendarAstrolabeObj.title }}
        </div>
        <div class="calendarAstrolabe__info">
          <div class="calendarAstrolabe__info__item">
            <div class="calendarAstrolabe__info__item__title">發生時間：</div>
            <div class="calendarAstrolabe__info__item__content">
              {{ format_time }}
            </div>
          </div>
          <div class="calendarAstrolabe__info__sp"></div>
          <div class="calendarAstrolabe__info__item">
            <div class="calendarAstrolabe__info__item__title">觀測地點：</div>
            <div class="calendarAstrolabe__info__item__content">
              <!-- {{ nowUserData.BirthPlace }} -->
              台灣台北
            </div>
          </div>
        </div>
        <div class="calendarAstrolabe__astrolabe">
          <canvasAstrolabe
            v-if="TcAstralInfo"
            class="canvas-pan"
            :width="paramPan.width - 2"
            :configItem="panConfigItem"
            panStyle="JOE2"
            :type="0"
            :data1="TcAstralInfo.AstralRadians"
            :data2="null"
            :Ret1="TcAstralInfo.RetAstralSet"
            :role="role"
          ></canvasAstrolabe>
          <div class="calendarAstrolabe__astrolabe__loading" v-else>
            星盤載入中...
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import canvasAstrolabe from "@/components/canvas-astrolabe.vue";

import TaiwanList from "@/js/taiwanList.json";
import moment from "moment";
export default {
  components: {
    canvasAstrolabe,
  },
  props: {
    calendarAstrolabeObj: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      paramPan: {
        width: 486,
        maxWidth: 486,
        panStyle: "A32",
      },
      TcAstralInfo: null,
      role: "Astrometry",
    };
  },
  computed: {
    panConfigItem() {
      //七個主要星
      let main = this.$store.state[this.role].mainPlanetList.slice(0, 7);
      //其他星
      let planet = this.$store.state[this.role].planetList;
      return [
        ...main.map((item) => (item.toggleSelect ? 1 : 0)), //星盤設置設定檔
        ...planet.map((item) => (item.toggleSelect ? 1 : 0)),
      ];
    },
    nowUserData() {
      return this.$store.state.nowUserData;
    },
    // findCountryCode() {
    //   let target = this.nowUserData.BirthCountry;
    //   let CountryCode = this.$CountryCode.find((item) => {
    //     return item.ChineseName == target;
    //   });
    //   return CountryCode ? CountryCode.Code.split(" ")[0] : "TW";
    // },
    format_time() {
      let day = new Date(this.calendarAstrolabeObj.start.d);
      return moment(day).format("YYYY-MM-DD HH:mm");
    },
    Arg() {
      // this.nowUserData;
      return {
        AstrolabeType: "SC",
        AstrolabeFileId: this.nowUserData.AstrolabeFileId,
        BirthTime: this.format_time,
        IsDaylight: false,
        BirthLocation: {
          City: "台灣",
          Country: "台灣",
          CountryCode: "TW",
          Place: "Taipei",
          TimeZone: 8,
          // Place: this.findPlaceEngName(this.nowUserData.BirthPlace),
          // Country: this.nowUserData.BirthCountry.split("(")[0],
          // State: this.nowUserData.BirthState,
          // City: this.nowUserData.BirthCity,
          // CountryCode: this.findCountryCode,
          // TimeZone: this.TimeZone,
          Latitude: null,
          Longitude: null,
        },
      };
    },
  },
  created() {
    if (window.innerWidth < 768) {
      this.paramPan.width = window.innerWidth - 120;
    }
    if (window.innerWidth < 425) {
      this.paramPan.width = window.innerWidth - 100;
    }
    this.update_timeLineData();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    update_timeLineData() {
      if (this.Arg && this.nowUserData) {
        this.$API.POST_TcAstralInfo(this.Arg).then((res) => {
          this.$store.commit("setLoading_force", false);
          this.ready = true;
          if (res.Data.Result) {
            this.TcAstralInfo = res.Data.AstralInfo;
          } else {
            alert("國家地點找不到");
            this.$router.replace("/friend?mode=timeline");
          }
        });
      }
    },
    // findPlaceEngName(ChineseName) {
    //   let engName = TaiwanList.find((item) => {
    //     return item.text == ChineseName;
    //   });
    //   if (!engName) alert("找無該區");
    //   return engName ? engName.value : "Taipei";
    // },
  },
};
</script>
<style lang="scss">
@import "@/scss/_mixin.scss";
.calendarAstrolabePopup {
  @include overlay;
  width: 100vw;
  height: 100vh;
  background: rgba(#f0f2f4, 0.4);
  backdrop-filter: blur(20px);
  .calendarAstrolabe {
    width: 622px;
    height: 734px;
    border-radius: 100px;
    padding: 26px 44px 52px;
    background-color: #214c73;
    box-shadow: -4px -4px 16px #ffffff, -4px -4px 10px #ffffff,
      4px 4px 25px rgba(0, 0, 0, 0.3);
    @include mobile {
      width: calc(100vw - 48px);
      height: auto;
      padding: 12px 20px 24px;
      border-radius: 50px;
    }
    &__title {
      text-align: center;
      font-weight: 700;
      font-size: 28px;
      line-height: 58px;
      color: #fff;
      @include mobile {
        font-size: 16px;
        line-height: 30px;
      }
    }
    &__info {
      display: flex;
      align-items: center;
      justify-content: center;
      @include mobile {
        flex-direction: column;
      }
      &__item {
        display: flex;
        &__title {
          font-size: 16px;
          color: #fff;
          line-height: 30px;
          @include mobile {
            font-size: 13px;
          }
        }
        &__content {
          font-size: 20px;
          color: #fff;
          line-height: 30px;
          @include mobile {
            font-size: 13px;
          }
        }
      }
      &__sp {
        width: 1px;
        height: 25px;
        margin: 0 20px;
        background-color: #adadad;
        @include mobile {
          display: none;
        }
      }
    }
    &__astrolabe {
      margin-top: 32px;
      width: 534px;
      height: 534px;
      background-color: #fff;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include mobile {
        margin-top: 12px;
        width: 100%;
        height: 320px;
      }
      &__loading {
        font-size: 16px;
        color: #17445c;
        @include mobile {
          font-size: 13px;
        }
      }
    }
  }
}
</style>
